function isDevEnvironment(hostname: string): boolean {
  return ['vercel.app', 'localhost'].some((url) => hostname.includes(url))
}

export function getSPMURLByHostname(hostname: string): string {
  // Vercel Preview has a random hostname, so we can't add it to the config
  if (isDevEnvironment(hostname)) {
    return 'https://staging-assets.signavio.com'
  }

  return 'https://prod-assets.signavio.com'
}
